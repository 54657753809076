html {
  font-family: nunito, sans-serif;
  background-color: #ffffff;
  /* base pixel size of 15 */
  height: 100%;
  font-size: 15px;
  overflow-x: hidden;
}

body {
  margin: 0;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

h1 {
  font-family: bebas-kai, sans-serif;
  font-size: 3.6em;
  margin: 0;
  font-weight: normal;
  letter-spacing: 0.05em;
}

/* header */
h2 {
  font-size: 1.733em;
  font-style: normal;
  font-weight: bold;
  margin: 0;
}

/* subheader */
h3 {
  font-size: 1.2em;
  font-style: normal;
  font-weight: bold;
  margin: 0;
}

/* boldparagraph */
h4 {
  font-size: 1em;
  font-style: normal;
  font-weight: 600;
  margin: 0;
}

/* tinyheader */
h5 {
  font-size: 0.8em;
  font-style: normal;
  font-weight: 600;
  margin: 0;
}

/* footer */
h6 {
  font-size: 0.767em;
  font-style: normal;
  font-weight: 600;
  margin: 0;
}

/* text */
p {
  font-size: 1em;
  font-style: normal;
  font-weight: normal;
  margin: 0;
}

* {
  box-sizing: border-box;
}

input,
select,
textarea,
button {
  font-family: inherit;
}

/* React datepicker specific */
.react-datepicker__input-container {
  width: inherit;
}

.react-datepicker-wrapper {
  width: 100%;
}

/* --- icomoon font setup --- */
@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.eot?8ggw2j');
  src: url('fonts/icomoon.eot?8ggw2j#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?8ggw2j') format('truetype'),
    url('fonts/icomoon.woff?8ggw2j') format('woff'),
    url('fonts/icomoon.svg?8ggw2j#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-calendar:before {
  content: '\e906';
}
.icon-camera:before {
  content: '\e904';
}
.icon-chevron-left:before {
  content: '\e900';
}
.icon-edit:before {
  content: '\e902';
}
.icon-send:before {
  content: '\e901';
}
.icon-tag:before {
  content: '\e908';
}
.icon-upload:before {
  content: '\e903';
}
.icon-user:before {
  content: '\e907';
}
.icon-x-circle:before {
  content: '\e905';
}
.icon-search:before {
  content: '\e986';
}
.icon-arrow-up2:before {
  content: '\ea3a';
}
.icon-google:before {
  content: '\ea88';
}
.icon-facebook:before {
  content: '\ea90';
}
.icon-instagram:before {
  content: '\ea92';
}
